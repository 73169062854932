// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
	margin-left: 0;
	margin-right: 0;
	max-width: 100%;
	height: auto;
}

.aligncenter {
	display: block;
	margin: ($line-height-computed / 2) auto;
	height: auto;
}

.alignleft,
.alignright {
	margin-bottom: ($line-height-computed / 2);
	height: auto;
}

@include breakpoint($small) {
	// Only float if not on an extra small device
	.alignleft {
		float: left;
		margin-right: ($line-height-computed / 2);
	}

	.alignright {
		float: right;
		margin-left: ($line-height-computed / 2);
	}
}

// Captions
.wp-caption {
}

.wp-caption-text {
	padding: ($line-height-computed / 2);
}

// Text meant only for screen readers
.screen-reader-text {
	@extend .sr-only;
	@extend .sr-only-focusable;
}
