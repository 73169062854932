// -------
// Infobox
// -------

.infobox {
	position: relative;
	padding: $padding-base-vertical * 2;
	padding-top: $padding-base-vertical * 3;

	&__container {
		@include container();
		max-width: 900px;
		position: relative;
	}

	&__slider.glide {
		@include breakpoint($small) {
			@include span(7 of 12);
		}
	}

	&__text {
		position: relative;
		margin: 0 auto;

		@include breakpoint($small) {
			@include span(8 of 12);
		}

		a {
			color: $text-color;
		}
	}

	&__slider + &__text {
		@include breakpoint($small) {
			@include span(5 of 12);
		}

		.infobox__background {
			padding: $padding-base-vertical;
		}
	}

	&__background {
		background-color: $off-white;
		padding: $padding-base-vertical * 2;
		width: 100%;
	}
}

.glide {
	$that: ".glide" !default;

	position: relative;
	width: 100%;
	height: 100%;

	&__wrapper {
		overflow: hidden;
	}

	&__track {
		position: relative;
		width: 100%;
		height: 100%;
		list-style: none;
		backface-visibility: hidden;
		transform-style: preserve-3d;
		touch-action: pan-Y;
		overflow: hidden;
		margin: 0;
		padding: 0;

		&.dragging {
			cursor: grabbing;
			user-select: none;
		}
	}

	&__slide {
		width: 100%;
		user-select: none;
		-webkit-touch-callout: none;
		-webkit-tap-highlight-color: transparent;

		a {
			user-select: none;
			-webkit-user-drag: none;
		}
	}

	&__arrows {
		-webkit-touch-callout: none;
		user-select: none;
		position: absolute;

		#{$that}--horizontal & {
			top: 50%;
			width: 92%;
			margin-left: 4%;
		}

		#{$that}--vertical & {
			left: 50%;
			height: 88%;
			margin-top: 6%;
		}
	}


	&__arrow {
		cursor: pointer;
		position: absolute;
		z-index: 2;
		color: $white;
		text-transform: uppercase;
		font-size: ms(5);
		padding: 9px 12px;
		background-color: transparent;
		border: 0;
		transition: border 300ms ease-in-out;

		&:focus {
			outline: none;
		}

		&:hover {
			border-color: $white;
		}

		#{$that}--horizontal & {
			transform: translateY(-50%);
			text-shadow: 1px 1px  4px $gray-base;

			&.prev {
				left: 0;
			}

			&.next {
				right: 0;
			}
		}

		#{$that}--vertical & {
			transform: translateX(-50%);

			&.prev {
				top: 0;
			}

			&.next {
				bottom: 0;
			}
		}
	}

	&__bullets {
		-webkit-touch-callout: none;
		user-select: none;
	}

	&--slider {
		#{$that}__slide {
			float: left;
			clear: none;
		}

		#{$that}__arrow {
			opacity: 1;
			transition: opacity 150ms ease;

			&.disabled {
				opacity: 1;
			}
		}
	}

	&--carousel {
		&#{$that}--vertical {
			#{$that}__track {
				overflow: visible;
			}
		}

		#{$that}__slide {
			float: left;
			clear: none;
		}

	}

	&--slideshow {
		#{$that}__wrapper {
			height: 100%;
		}

		#{$that}__slide {
			height: 100%;
			position: absolute;
			top: 0; left: 0;
			z-index: -1;
			// Flashing issue fix
			-webkit-transform-style: preserve-3d;
			-webkit-transform: translate3d(0, 0, 0);

			&.active {
				z-index: 1;
			}
		}
	}
}

@include breakpoint($small) {
	.flexbox {
		.infobox {

			&__container {
				display: flex;
				align-items: stretch;
			}

			&__text {
				display: flex;
				align-items: stretch;
			}

			&__background {

			}

		}
	}
}
