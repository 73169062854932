// -----------
// Team Styles
// -----------

.teamlist {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;
	background-color: $off-white;

	&__list {
		@include container();
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
		list-style: none;
	}

	&__listitem {
		@include span(12 of 12);
		margin-bottom: $padding-base-vertical * 2;

		@include breakpoint($small) {
			@include span(6 of 12);
		}
	}

	&__thumbnail-container {
		@include container();
		position: relative;
		width: 100%;
		overflow: hidden;

		&:after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}
	}

	&__thumbnail {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 100%;
		width: auto;
		transform: translate(-50%, -50%);
	}

	&__thumbnail--portrait {
		width: 100%;
		height: auto;
	}

	&__title {
		display: block;
		color: $text-color;
		font-weight: 500;
		padding: 0 $padding-base-vertical / 2;
	}

	&__description {
		padding: 0 $padding-base-vertical / 2;
		font-weight: 300;
	}

	&__job {
		margin-bottom: 0;
	}

	&__mail {
		color: $text-color;

		&:hover {
			color: $brand-secondary;
		}
	}
}
