// ----------
// Google Map
// ----------

.map {
	@include container();
	padding: $padding-base-vertical * 2 0;
	position: relative;

	&__outer-container {
		@include container();
		max-width: 1000px;
	}

	&__text {
		@include span(12 of 12);
		font-weight: 300;

		@include breakpoint($small) {
			@include span(6 of 12);
		}
	}

	&__background {
		background-color: $off-white;
		padding: $padding-base-vertical * 2;
	}

	&__wrapper {
		@include container();
		position: relative;

		@include breakpoint($small) {
			@include span(6 of 12);
		}
	}

	&__container {
		width: 100%;
		min-height: 400px;

		/* fixes potential theme css conflict */
		& img {
			max-width: inherit !important;
		}
	}
}

@include breakpoint($small) {
	.flexbox {
		.map {
			&__outer-container {
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
			}

			&__wrapper {
				display: flex;
				align-items: stretch;
			}
		}
	}
}

