@mixin btn($color: $brand-primary, $size: normal, $style: rounded, $outline: true) {
	border: 1px solid $color;
	outline: none;
	transition-duration: .3s;
	cursor: pointer;
	padding-top: $padding-base-vertical;
	padding-bottom: $padding-base-vertical;
	padding-left: $padding-base-horizontal * 2;
	padding-right: $padding-base-horizontal * 2;
	color: $color;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 2px;
	display: inline-block;
	-webkit-appearance: none;
	transition-property: background, color;

	@if $size == "small" {
		font-size: ms(-3);
	} @else {
		font-size: ms(-1);
	}

	@if $style == "pill" {
		border-radius: $padding-base-vertical;
	} @else if $style == "rounded" {
		border-radius: $border-radius-base;
	} @else {
		border-radius: 0;
	}

	@if $outline == true {
		background: none;

		&:hover {
			color: $white;
			background: $color;
		}

	} @else {
		color: $white;
		background: $color;

		&:hover {
			color: $color;
			background: none;
		}

	}
}

%btn--primary {
	@include btn($brand-primary, "normal", "rounded", false);
}

%btn--secondary {
	@include btn($brand-secondary, "normal", "rounded", false);
}

%btn--secondary-small {
	@include btn($brand-secondary, "small", "rounded", false);
}

%btn--secondary-outline {
	@include btn($brand-secondary, "normal", "rounded", true);
}

%btn--secondary-outline-small {
	@include btn($brand-secondary, "small", "rounded", true);
}

%btn--white {
	@include btn($white, "normal", "rounded", true);

	&:hover {
		color: $gray-base;
	}
}

%btn--white-small {
	@include btn($white, "small", "rounded", true);

	&:hover {
		color: $gray-base;
	}
}

