.footer {
	position: relative;
	padding: $padding-base-vertical * 4 0;
	background-color: $footer-bg;

	&__container {
		@include container();
		width: span(10 of 12);
		margin-left: auto;
		margin-right: auto;
	}

	.widget_nav_menu {
		@include span(6 of 12);
		font-size: ms(-1);

		h3 {
			@include typi($h3-footer-map);
			text-transform: uppercase;
			margin-bottom: $padding-base-vertical / 2;
		}

		.menu {
			list-style: none;
			margin: 0;
			padding: 0;
			font-weight: 300;
		}

		.menu-item {
			a {
				color: $text-color;
			}
		}

		@include breakpoint($small) {
			@include span(3 of 12);
		}
	}

	&__copyright {
		padding: $padding-base-vertical 0;
		background-color: $footer-bg;

		p {
			text-align: center;
			font-size: ms(-1);
			margin: 0;
		}
	}

	&__decoration {
		@include container();
		height: 100%;
		margin-top: $padding-base-vertical * 4;
	}

	&__svg {
		fill: $footer-bg;
		width: 100%;
		height: 100%;
		display: block;
		top: 2px;
		position: relative;
	}

	&__stores {
		@include container();
		margin-top: $padding-base-vertical * 3;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	&__store {
		display: inline-block;
		overflow: hidden;
		width: 165px;
		height: 40px;
		text-align: center;
		margin-bottom: $padding-base-vertical;
		vertical-align: middle;

		img {
			height: 100%;
			width: auto;
		}
	}
}
