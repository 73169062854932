@function ms-calc($Value, $Base: $ms-base, $Ratio: $ms-ratio) {
  
  // If pow exists use it.
  // It supports non-interger values!
  @if $MS-pow-exists {

    // The formula for figuring out modular scales is:
    // (r^v)*b
    @return pow($Ratio, $Value) * $Base;
  }

  // If not, use ms-pow().
  // Not as fast or capable of non-integer exponents.
  @else {
    @return ms-pow($Ratio, $Value) * $Base;
  }
}