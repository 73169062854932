// --------------
// Call to Action
// --------------

.cta {
	@include container();
	padding: $padding-base-vertical * 2 0;
	text-align: center;
	position: relative;

	&--photo {
		background-size: cover;
		background-position: center center;
		color: $white;

		.cta__link {
			@extend %btn--white-small;
			color: $white;
		}

		&:after {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: rgba($gray-base, .7);
			top: 0;
			left: 0;
		}
	}

	&--white {
		background-color: $white;

		.cta__link {
			@extend %btn--secondary-outline-small;
		}
	}

	&--gray {
		background-color: $off-white;

		.cta__link {
			@extend %btn--secondary-small;
		}
	}

	&__container {
		position: relative;
		z-index: 1;
		margin-right: auto;
		margin-left: auto;
	}

	&__content {
		display: inline-block;
		width: 95%;
	}

	&__headline {
		@include typi($h2-cta-map);
		margin-top: 0;
		margin-bottom: $padding-base-vertical;
		font-weight: 400;
	}

	&__text {
		margin-bottom: $padding-base-vertical;

		p {
			margin: 0;
			font-weight: 300;
		}
	}

	@include breakpoint($small) {
		display: table;
		min-height: 200px;
		width: 100%;

		&__container {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
		}
	}

	@include breakpoint($medium) {
		min-height: 250px;
	}
}
