// Gutter Syntax
// =============


// Gutters
// -------
// Set gutters on an element.
// - [$span]   : <settings>
@mixin gutters(
  $span: $susy
) {
  $inspect  : $span;
  $span     : parse-gutters($span);
  $_gutters : get-gutters($span);

  $_output: (
    before: map-get($_gutters, before),
    after: map-get($_gutters, after),
    flow: susy-get(flow, $span),
  );

  @include susy-inspect(gutters, $inspect);

  @if is-inside($span) {
    @include padding-output($_output...);
  } @else {
    @include margin-output($_output...);
  }
}

@mixin gutter(
  $span: $susy
) {
  @include gutters($span);
}


// Gutter
// ------
// Return the width of a gutter.
// - [$span]   : <settings>
@function gutter(
  $span: $susy
) {
  $span: parse-gutters($span);

  $_gutters: get-gutters($span);
  $_gutters: map-get($_gutters, before) or map-get($_gutters, after);

  @return $_gutters;
}

@function gutters(
  $span: $susy
) {
  @return gutter($span);
}


// Get Gutter Width
// ----------------
// Return gutter width.
// - [$context]: <context>
@function get-gutter-width(
  $context: $susy
) {
  $context  : parse-gutters($context);

  $_gutters : susy-get(gutters, $context);
  $_gutter  : susy-get(gutter-override, $context);

  @if $_gutters and ($_gutters > 0) and not($_gutter) {
    $_column-width: susy-get(column-width, $context);
    $_math: gutter-math($context);
    @if $_math == static {
      $_gutter: $_gutters * valid-column-math($_math, $_column-width);
    } @else {
      $_columns : susy-get(columns, $context);
      $_spread  : if(is-split($context), wide, susy-get(spread, $context));
      $_gutter  : percentage($_gutters / susy-sum($_columns, $_gutters, $_spread));
    }
  }

  $_gutter: if($_gutter == 'no-gutters' or $_gutter == 'no-gutter', null, $_gutter);

  @return $_gutter;
}


// Get Gutters
// -----------
// Return before and after gutter values.
// - [$context]: <context>
@function get-gutters(
  $context: $susy
) {
  $context            : parse-gutters($context);

  $_gutter-position   : susy-get(gutter-position, $context);
  $_gutter            : get-gutter-width($context);

  $_return            : (before: null, after: null);

  @if is-split($context) and $_gutter {
    $_gutter: $_gutter / 2;
    $_return: map-merge($_return, (before: $_gutter, after: $_gutter));
  } @else {
    $_return: map-merge($_return, ($_gutter-position: $_gutter));
  }

  @return $_return;
}


// Is Inside
// ---------
// Returns true if gutters are inside.
// $context: <context>
@function is-inside(
  $context
) {
  $_inside: inside inside-static;
  $_gutter-position: susy-get(gutter-position, $context);

  @return if(index($_inside, $_gutter-position), true, false);
}


// Is Split
// --------
// Returns true if gutters are split.
// $context: <context>
@function is-split(
  $context
) {
  $_split: split inside inside-static;
  $_gutter-position: susy-get(gutter-position, $context);

  @return if(index($_split, $_gutter-position), true, false);
}


// Gutter Math
// -----------
// Return the math to use for gutter calculations
// $context: <context>
@function gutter-math(
  $context: $susy
) {
  $_return  : susy-get(math, $context);
  $_return  : if(susy-get(gutter-position, $context) == inside-static, static, $_return);

  @return $_return;
}
