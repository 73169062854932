* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

// [aria-hidden="true"] {
// 	visibility: hidden;
// }

html {
	-webkit-tap-highlight-color: rgba($gray-base, 0);
}

body {
	@include font-smoothing;
	font-size: ms(0);
	font-family: $font-family-base;
	line-height: $line-height-base;
	color: $text-color;
	background-color: $body-bg;
}

p {
	@include typi($typi);
}

img {
	max-width: 100%;
	height: auto;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

// Links

a {
	color: $link-color;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus {
		@include tab-focus;
	}
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: 0;
	outline : 0;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

// Embeds responsive
//
// Credit: Nicolas Gallagher and SUIT CSS.

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
	padding-bottom: 75%;
}

.error404 .page-header {
	display: none;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
	cursor: pointer;
}

::selection {
	background: $brand-secondary;
	color: $white;
}

// Global Styles for the contentmodules
.feature + .module {

	> * {
		position: relative;
		z-index: 3;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: -50px;
		width: 100%;
		background: $white;
		height: 100px;
		transform: skew(0deg, -2deg);
		z-index: 2;
	}
}
