// ---------
// Textblock
// ---------

.textblock {
  @include container();
  padding: $padding-base-vertical * 2 0;
  position: relative;

  &--white {
    background-color: $white;
  }

  &--gray {
    background-color: $off-white;
  }

  &--large {
    padding-bottom: $padding-base-vertical 0;
    font-size: ms(1);
  }

  &--small {
    font-size: ms(-1);
  }

  h1,
  h2,
  h3 {
    width: span(11 of 12);
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
  }

  p {
    width: span(11 of 12);
    margin-right: auto;
    margin-left: auto;
    font-weight: 300;
    margin-top: 0;
    max-width: 800px;
    margin-bottom: $padding-base-vertical * 2;

    &:last-child {
      margin-bottom: 0;

      .textblock__button {
        margin-bottom: 0;
      }
    }
  }

  a {
    font-weight: 700;
  }

  img {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    height: auto;
    width: 100%;
  }

  &__stores {
    @include container();
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__column {
    @include span(12 of 12);
    padding: $padding-base-vertical * 2;

    @include breakpoint($small) {
      @include span(6 of 12);
    }

    &--grey {
      background-color: $off-white;
    }
  }

  &__store {
    display: inline-block;
    overflow: hidden;
    width: 309px;
    height: 75px;
    text-align: center;

    &--apple {
      background-size: 309px 75px !important;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .wp-caption {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;

    &-text {
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 2px;
      font-weight: 600;
      max-width: 800px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .textblock__button {
    margin-bottom: $padding-base-vertical * 3;
    display: block;

    a {
      @extend %btn--secondary;
    }

    &--outline {
      a {
        @extend %btn--secondary-outline;
      }
    }
  }

  h3 {
    margin-top: $padding-base-vertical * 2;
  }
}

.flexbox {
  .wp-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      display: flex;
      order: 2;
    }

    .wp-caption-text {
      display: flex;
      order: 1;
    }
  }
}
