// -----------
// Cars Styles
// -----------

.cars {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;

	&__list {
		@include container();
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
		list-style: none;
		margin-bottom: 0;
	}

	&__listitem {
		@include span(6 of 12);
		margin-bottom: $padding-base-vertical * 2;

		@include breakpoint($small) {
			@include span(4 of 12);

			// &:nth-child(n+4) {
			// 	margin-bottom: 0;
			// }
		}

		// &:first-child {
		// 	width: 100%;
		// }
	}

	&__title {
		display: block;
		color: $text-color;
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;
		margin-bottom: $padding-base-vertical;
		letter-spacing: 2px;
		transition: color .3s;

		&:hover {
			color: $brand-secondary;
			text-decoration: none;
		}

		&:active {
			color: $text-color;
			text-decoration: none;
		}
	}

	&__description {
		margin-top: $padding-base-vertical / 2;
		font-weight: 300;
		text-align: center;
	}
}

.flexbox {
	.cars__list {
		display: flex;
		flex-wrap: wrap;
	}

	.cars__listitem {
		flex: 1 0 auto;
	}
}
