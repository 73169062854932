// ---------------
// Postlist Styles
// ---------------

.postlist {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;

	&__headline {
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		margin-top: 0;
		letter-spacing: 2px;
		margin-bottom: $padding-base-vertical * 2;
	}

	&__list {
		@include container();
		margin-left: auto;
		margin-right: auto;
		list-style: none;
		max-width: 800px;
		background-color: $off-white;
		padding: $padding-base-vertical * 2;
	}

	&__listitem {
		@include span(12 of 12);
		position: relative;
		margin-bottom: $padding-base-vertical / 2;
	}

	&__title {
		display: block;
		color: $text-color;
		text-align: center;
		font-weight: 400;

		&:hover {
			text-decoration: none;
			font-weight: 600;
			color: $text-color;
		}
	}
}
