// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

// Override content-box in Normalize (* isn't specific enough)
input[type="search"] {
	box-sizing: border-box;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9; // IE8-9
	line-height: normal;
}

input[type="file"] {
	display: block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
	display: block;
	width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
	height: auto;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	@include tab-focus;
}

// Adjust output element
output {
	display: block;
	padding-top: ($padding-base-vertical + 1);
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $text-color;
}

%form-control {
	display: block;
	width: 100%;
	height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: lighten($brand-secondary, 30%);
	background-color: $white;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border: 1px solid lighten($brand-secondary, 30%);
	border-radius: $border-radius-base; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
	transition: border-color ease-in-out .15s;
	-webkit-appearance: none;

	// Customize the `:focus` state to imitate native WebKit styles.
	@include form-control-focus;

	// Placeholder
	@include placeholder;

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color: $gray-base;
		opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
	}

	&[readonly] {
		background-color: $white;
	}
}

// Reset height for `textarea`s
textarea {
	height: auto;
}

// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		line-height: $input-height-base;
	}
}

// Search form
.search-form {
}

.search-form label {
	font-weight: normal;
}

.search-form .search-field {
}

.search-form .search-submit {
}

.textblock {
	.gform_wrapper {
		width: span(11 of 12);
		margin-left: auto;
		margin-right: auto;

		form {
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;

			.button {
				@extend %btn--secondary;
				display: block !important;
				margin-right: auto;
				margin-left: auto;
			}

			.gform_footer {
				.button {
					font-size: ms(-1) !important;
				}
			}

			select,
			textarea,
			input[type="text"],
			input[type="password"],
			input[type="datetime"],
			input[type="datetime-local"],
			input[type="date"],
			input[type="month"],
			input[type="time"],
			input[type="week"],
			input[type="number"],
			input[type="email"],
			input[type="url"],
			input[type="search"],
			input[type="tel"],
			input[type="color"] {
				@extend %form-control;
				padding-left: $padding-base-vertical / 2;
			}
		}

		.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name {
			@include breakpoint($small) {
				width: 100%;
				clear: none;
			}
		}

		.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name span{
			display: block;
			width: 100%;
			padding-right: 0;
			min-height: $input-height-base;

			@include breakpoint($small) {
				@include span(3 of 6);
				clear: none;

				&.name_first {
					padding-left: 0;
					margin-right: 0;
				}

				&.name_last {
					padding-right: 0;
					padding-left: gutter(6);
				}
			}
		}

		.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name span{
			display: block;
			width: 100%;
			padding-right: 0;
			min-height: $input-height-base;

			@include breakpoint($small) {
				@include span(3 of 6);
				clear: none;

				&.name_first {
					padding-left: 0;
					margin-right: 0;
				}

				&.name_last {
					padding-right: 0;
					padding-left: gutter(6);
				}
			}
		}

		#field_1_5 {
			.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name .name_first,
			.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name .name_last {
				@include breakpoint($small) {
					@include span(6 of 12);
				}
			}

			.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name .name_first {
				@include breakpoint($small) {
				}
			}

			.ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name .name_last {
				@include breakpoint($small) {
					padding-left: gutter(12) !important;
				}
			}
		}


		.ginput_complex .ginput_full input[type="text"] {
			width: 100% !important;
		}

		label.gfield_label + div.ginput_container {
			@include clearfix();
			margin-top: 0;
		}

		.field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text] {
			margin-bottom: 0;
		}

		.ginput_container_select {
			@include container();
			margin-top: $padding-base-vertical !important;
		}

		ul.gform_fields li.gfield select {
			margin-right: 0 !important;
			margin-left: 0 !important;
			width: 100%;
			border-radius: 3px !important;
			-webkit-appearance: none;
		}

		li.hidden_label {
			@include container();
		}

		#field_3_6 {
			.ginput_container_multiselect {
				width: 100%;

				select[multiple=multiple] option {
					text-indent: 0;
					padding-left: 0;
					padding-bottom: $padding-base-vertical / 4;
					padding-top: $padding-base-vertical / 4;
				}
			}
		}

		#field_1_13 {

			.ginput_container_number {
				float: right !important;
			}

			#input_1_13 {
				text-align: right;
			}
		}

		#field_1_12 {

			.ginput_container_number {
				float: right !important;
			}

			#input_1_12 {
				text-align: right;
			}
		}

		#field_1_14 {
			text-align: right;
		}

		#field_1_12,
		#field_1_13,
		#field_1_14 {
			color: $brand-secondary;

			.ginput_container {
				float: left;
			}

			.large {
				border: 0;
				color: $brand-secondary;
				font-weight: bold;
			}
		}

		#field_1_13 {
			padding-top: $padding-base-vertical;
			padding-bottom: $padding-base-vertical;

			span {
				font-weight: bold;
				font-size: ms(0);
			}
		}

		#field_4_4 {
			&.gfield {
				padding-top: 16px;
				margin-top: 0;
				padding-right: .8333333333%;
			}
		}


		#field_4_5,
		#field_4_6 {
			margin-top: 0;
		}

		#field_4_6 {
			margin-top: .8em;

			#gform_browse_button_4_6 {
				@extend %btn--secondary-outline;
				font-size: ms(-4);
				letter-spacing: 1px;
			}
		}

		#field_2_7 {
			margin-top: $padding-base-vertical;

			li {
				margin-bottom: 0;
			}
		}

		#field_1_9,
		#field_1_4 {
			select {
				-webkit-appearance: none;
			}
		}

		#field_2_5,
		#field_1_10 {
			margin-top: $padding-base-vertical;

			.textarea {
				height: 80px;
			}
		}

		#field_1_1,
		#field_1_2 {
			@include container();
			margin-top: $padding-base-vertical;

			.ginput_product_price_label,
			.ginput_product_price,
			.ginput_quantity_label {
				display: none;
			}

			.ginput_container_singleproduct,
			.gfield_label {
				@include span(6 of 12);
			}

			.gfield_label {
				line-height: 40px;
				color: $brand-secondary;
			}
		}

		#field_1_3 {
			text-align: center;
			color: $brand-secondary;
			font-weight: bold;

			.ginput_container_total span{
				color: $brand-secondary;
			}
		}

		.gfield_select,
		.chosen-container-multi .chosen-choices {
			background-image: url("../images/svg/dropdown_arrow.svg") !important;
			background-repeat: no-repeat;
			background-size: 10px;
			background-position: 97% center;
			cursor: pointer;
			-moz-appearance: none;
			font-weight: 300 !important;
		}

		#field_3_11 .gfield_select,
		#field_3_6 .gfield_select,
		#field_3_6 .chosen-container {
			width: 100% !important;
		}

		.gsection {
			margin-top: $padding-base-vertical;
			margin-bottom: $padding-base-vertical;
			border-bottom: 0;
			border-top: 1px solid $brand-secondary;
			width: 100%;
			margin-right: auto;
			margin-left: auto !important;

			h2 {
				border: none;
			}
		}

		.gfield_checkbox,
		.gfield_radio {
			@include container();
			background-color: $white;
			width: 100%;
			padding: $padding-base-vertical / 4 0;
			color: $brand-secondary;
			font-size: ms(1);

			li {
				@include span(6 of 12);
				margin-bottom: $padding-base-vertical / 2;
				cursor: pointer;

				label {
					margin-left: 0;
				}
			}

			li input[type="checkbox"]:checked + label,
			li input[type="radio"]:checked + label {
				font-weight: normal;
			}

			input[type="radio"] {
				position: absolute;
				overflow: hidden;
				clip: rect(0 0 0 0);
				height: 1px;
				width: 1px;
				margin: -1px;
				padding: 0;
			}

			input[type="radio"] + label {
				position: relative;

				&::before {
					content: "";
					position: relative;
					display: inline-block;
					background: $white;
					border: 1px solid $brand-secondary;
					width: 20px;
					height: 20px;
					border-radius: 100%;
					cursor: pointer;
					margin-right: $padding-base-vertical / 4;
					vertical-align: middle;
				}

				&::after {
					content: "";
					position: absolute;
					cursor: pointer;
					left: 5px;
					display: block;
					height: 10px;
					width: 10px;
					top: 10px;
					background: $brand-secondary;
					border-radius: 100%;
					transition: opacity 0.3s;
					opacity: 0;
				}
			}

			input[type="radio"]:checked + label::after {
				opacity: 1;
				transition: opacity .3s;
			}
		}

		.validation_error {
			color: $brand-primary;
			border-color: $brand-primary;
		}

		li.gfield.gfield_error {
			background-color: $off-white;
			border-color: $brand-primary;

			input {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		li.gfield.gfield_error.gfield_contains_required div.ginput_container {
			margin-left: 0;
		}

		.gfield_description.validation_message {
			margin-top: .375em;
		}

		.validation_message {
			color: $brand-primary;
		}

		@include breakpoint($small) {
			#field_3_1,
			#field_4_1, {
				@include span(6 of 12);
				clear: none;
			}

			#field_3_2 .address_line_1 {
				@include span(12 of 12);
				padding-left: 0;
				padding-right: 0;
			}

			.address_line_1 {
				@include container();
				@include span(6 of 12);
				padding-right: 0.4333%;
			}

			.ginput_left.address_city,
			.ginput_right.address_zip {
				@include span(3 of 12);
				padding-left: 1.333%;
				padding-right: 0.233333%;
			}

			.ginput_right.address_zip {
				margin-left: 0;
				padding-right: 0 !important;
			}

			#field_3_3,
			#field_3_4,
			#field_3_7,
			#field_3_8,
			#field_4_2,
			#field_4_3 {
				@include span(3 of 12);
				clear: none;
				display: block;
			}

			#field_4_5 {
				@include span(6 of 12);
				margin-top: .8em !important;
				clear: none;
			}

			#field_4_6 {
				margin-top: .8em !important;
			}

			#field_2_7,
			#field_2_8 {
				position: relative;

				.ginput_container {
					max-width: 600px;
					margin-left: auto;
					margin-right: auto;
					position: relative;
				}
			}

			#field_2_5 .textarea {
				height: 160px;
			}

			#field_2_10 {
				max-width: 600px;
			}

			#field_3_2,
			#field_3_6,
			#field_4_6,
			#field_2_4,
			#field_2_2,
			#field_1_6,
			#field_1_7,
			#field_1_9,
			#field_1_4 {
				@include span(6 of 12);
				position: relative;
				clear: both;
			}

			#field_3_6,
			#field_4_6,
			#field_2_2,
			#field_2_4,
			#field_1_6,
			#field_1_7,
			#field_1_9,
			#field_1_4 {
				clear: none;
			}

			#field_2_2,
			#field_2_4 {
				margin-bottom: $padding-base-vertical;
			}

			#field_1_10 {
				margin-bottom: $padding-base-vertical * 2;
			}

			#field_3_11 {
				@include span(6 of 12);
				float: left;
			}

			#field_1_10 {
				@include span(12 of 12);
			}

			#field_1_1,
			#field_1_2,
			#field_1_11,
			#field_1_12,
			#field_1_13,
			#field_1_14 {
				position: relative;
				max-width: 300px;
				margin-left: auto !important;
				margin-right: auto;

				.ginput_container {
					max-width: 600px;
					margin-left: auto;
					margin-right: auto;
					position: relative;
					display: inline;
				}
			}

			#field_4_6 {
				margin-top: $padding-base-vertical;

				#gform_browse_button_4_6 {
					// @extend %btn--secondary-outline;
					font-size: ms(-3);
					margin-top: $padding-base-vertical / 2;
				}
			}

			#field_4_5 {
				textarea {
					height: 80px !important;
				}
			}
		}

		#field_1_8 {
			.address_line_1 {
				// padding-left: 0;
			}

			.address_zip {
				// padding-right: 0;
			}
		}

		.gform_fileupload_multifile .gform_drop_area {
			border: 1px solid lighten($brand-secondary, 30%);
			border-radius: $border-radius-base;
			height: 80px;
			padding: 5px;
			color: lighten($brand-secondary, 30%);
		}

		.chosen-container {
			@extend %form-control;
		}

		.chosen-container-multi .chosen-choices {
			height: 100% !important;
			border: 0;
			background-image: none;

			li.search-field {
				height: 100%;

				input[type="text"] {
					height: 100%;
					color: lighten($brand-secondary, 30%);
					font-family: $font-family-base;
				}
			}
		}

		li.hidden_label input {
			margin-top: 0;

			&.datepicker {
				width: 100% !important;
			}
		}

		.ginput_container_multiselect {
			margin-top: $padding-base-vertical !important;
			float: left;
		}

		.gfield_date_dropdown_day,
		.gfield_date_dropdown_month,
		.gfield_date_dropdown_year {
			@include span(4 of 12);
			margin-top: $padding-base-vertical;
			margin-right: 0;
		}

		.instruction {
			display: none;
		}

		.clear-multi {
			@include container();
		}

		.gfield_date_day,
		.gfield_date_month,
		.gfield_date_year,
		.gfield_time_hour,
		.gfield_time_minute {
			@include span(3 of 6);

			input[type="text"],
			i {
				float: left !important;
			}

			input[type="text"] {
				width: 100% !important;
			}

			i {
				width: auto;
				display: none !important;
			}
		}

		.gfield_time_hour {
			padding-left: 0;
		}

		.gfield_time_minute {
			padding-right: 0;
		}
	}
}

// jQuery UI Calendar Style
.ui-widget {
	background: $white;
	border: none;
	border-radius: 0;
}

.ui-datepicker {
	padding: 0;

	tbody td {
		border-color: $gray-lighter !important;
	}
}

#ui-datepicker-div {
	box-shadow: 0 1px 5px $gray-light;

	.ui-datepicker-header {
		border: 0;
		background-color: $off-white;
		font-weight: normal;
		font-size: 15px;
		text-shadow: none;
		color: $text-color;
	}

	.ui-datepicker .ui-datepicker-title {
		margin-top: .4em;
		margin-bottom: .3em;
		color: $text-color;
	}

	.ui-datepicker table {
		margin: 0;
	}

	.ui-datepicker th {
		padding: 1em 0;
		color: $off-white;
		font-size: 13px;
		font-weight: normal;
		border: none;
		border-top: 1px solid #3a414d;
	}

	.ui-datepicker td {
		background: $brand-primary;
		border: 0;
		padding: 0;
		line-height: 32px;
	}

	.ui-datepicker tbody tr {
	}

	td .ui-state-default {
		background: transparent;
		border: none;
		text-align: center;
		padding: .5em;
		margin: 0;
		font-weight: normal;
		color: $text-color;
		line-height: 15px;
		font-size: 15px;
		box-shadow: none;
		text-shadow: none;
	}

	.ui-state-disabled  {
		opacity: 1;
		background-color: $off-white;
	}

	.ui-state-disabled .ui-state-default {
		color: $off-white;
	}

	td .ui-state-active,
	td .ui-state-hover {
		background: $brand-secondary;
		color: $white;
	}

}

.gform_wrapper.gf_browser_safari select[multiple=multiple].gfield_select {
	height: 40px !important;
}

.gform_wrapper .gform_footer {
	padding-left: .8333% !important;
}

.gform_wrapper .top_label .gfield_label {
	float: left;
}

.gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
	margin-right: 0 !important;
}

.gform_confirmation_wrapper {
	@include container();
	position: relative;
}

.gform_confirmation_message {
	@include span(12 of 12);
	text-align: center;
	padding: $padding-base-vertical * 2;
	background-color: $brand-primary;
	font-size: ms(3);
	color: $white;
	font-weight: 600;
}

#gform_wrapper_2 {
	display: block !important;
}

.textblock .gform_wrapper .gfield_checkbox li label,
.textblock .gform_wrapper .gfield_radio li label {
	font-size: ms(-1);
	line-height: 1.8;

	@include breakpoint($medium) {
		line-height: 1.2;
		font-size: ms(1);
	}
}

@media only screen and (min-width: 321px) {
	.gform_wrapper .gfield_time_ampm,
	.gform_wrapper .gfield_time_hour,
	.gform_wrapper .gfield_time_minute {
		max-width: 100% !important;
	}
}

.gform_wrapper .gfield_time_minute {
	margin-right: 0 !important;
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
	padding: 0;
	padding-left: $padding-base-vertical / 2 !important;
}

body .gform_wrapper .top_label div.ginput_container {
	margin-top: 0 !important;
}

.gform_wrapper .ginput_container.ginput_container_multiselect .chosen-container.chosen-container-multi ul.chosen-choices li.search-field input.default {
	height: 100% !important;
	margin: 0 !important;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first, .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last, .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle {
	padding-top: 0 !important;
}
