// -------------------
// helpful smaller css mixins
// -------------------

@mixin cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin center($xy:xy) {
	@if $xy == xy {
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		transform: translate(-50%, -50%);
	} @else if $xy == x {
		left: 50%;
		right: auto;
		transform: translateX(-50%);
	} @else if $xy == y {
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
	}
}

@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	-moz-font-feature-settings: "liga" on;
}

// WebKit-style focus

@mixin tab-focus() {
	// Default
	outline: thin dotted;
	// WebKit
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

@mixin hide-text() {
	border: 0;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus($color: $input-border-focus) {
	$color-rgba: rgba(red($color), green($color), blue($color), .6);
	&:focus {
		border-color: $color;
		outline: 0;
	}
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
	// Firefox
	&::-moz-placeholder {
		color: $color;
		opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
		font-weight: 300;
	}

	&:-ms-input-placeholder {
	color: $color;
	font-weight: 300;
	} // Internet Explorer 10+

	&::-webkit-input-placeholder {
	color: $color;
	font-weight: 300;
	} // Safari and Chrome
}
