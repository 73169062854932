// rem Support
// ===========

// rem
// ---
// Check for an existing support mixin, or output directly.
// - $prop  : <css property>
// - $val   : <css value>
@mixin susy-rem(
  $prop,
  $val
) {
  $_reqs: (
    variable: rhythm-unit rem-with-px-fallback,
    mixin: rem,
  );
  @if susy-support(rem, $_reqs, $warn: false) and $rhythm-unit == rem {
    @include rem($prop, $val);
  } @else {
    #{$prop}: $val;
  }
}
