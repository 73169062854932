// --------------
// donation Module
// --------------
@-webkit-keyframes progress-bar {
	0% { width: 0; }
}
@-moz-keyframes progress-bar {
	0% { width: 0; }
}
@keyframes progress-bar {
	0% { width: 0; }
}
.donation {
	@include container();
	padding: $padding-base-vertical * 2 0;
	text-align: left;
	position: relative;
	background-size: cover;
	background-position: center center;
	overflow: hidden;

	&--photo {
		background-size: cover;
		background-position: center center;
		color: $white;

		&.donation--no-bg {
			background-color: $brand-secondary;

			&:before {
				display: none;
			}
		}
	}

	&:before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: rgba($gray-base, .5);
		top: 0;
		left: 0;
		z-index: 1;
	}

	&::after{
		border-bottom: 1px dashed $off-white;
		margin-top: -45px;
		z-index: 2;
		position: relative;

		@media screen and (max-width: 767px) {
			margin-top: -51px;
		}

		@media screen and (max-width: 576px) {
			margin-top: -25px;
		}
	}

	&__video {
		display: none;

		@include breakpoint($medium) {
			display: block;
		}
	}

	&__link {
		@extend %btn--white-small;

		&:hover {
			text-decoration: none;
			background-color: $brand-primary;
			border-color: $brand-primary;
		}

		&:focus {
			outline: 0;
			text-decoration: none;
			color: $white;
		}

		@include breakpoint($medium) {
			font-size: ms(-2);
		}
	}

	&__container {
		position: relative;
		z-index: 3;
		margin-right: auto;
		margin-left: auto;
	}

	&__content {
		display: block;
		width: span(11 of 12);
		margin-left: span(1 of 12);

		@include breakpoint($medium) {
			width: span(6 of 12);
			margin-left: span(2 of 12);
			margin-top: $padding-base-vertical * 2;
		}
	}

	&__headline {
		@include typi($h1-feature-map);
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 700;
		color: $brand-primary;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	&__subheadline {
		@include typi($h2-feature-map);
		margin-top: $padding-base-vertical / 2;
		margin-bottom: $padding-base-vertical;
		font-weight: 300;
		color: $white;
	}

	&__goal {
		&--container {
			margin-top: 9rem;
			display: flex;
			margin-left: span(2 of 12);
			width: span(8 of 12);
			z-index: 2;
			position: relative;
		}

		&--border {
			display: inline-block;
			border-bottom: 2px solid $off-white;
			color: $off-white;
			transform: rotate(-90deg);
			transform-origin: bottom left;
			height: 2.5rem;
			width: 7.5rem;
			padding-left: 5px;

			@media screen and (max-width: 767px) {
				height: 2rem;
			}

			@media screen and (max-width: 576px) {
				height: 1.125rem;
				width: 3.5rem;
				border-bottom: 1px solid $off-white;
				padding-left: 2px;
			}

			&.no-border {
				border-bottom: 0px;
				transform: rotate(0);
				transform-origin: none;
				margin-top: calc(-115% - 10px);

				@media screen and (max-width: 576px) {
					margin-top: calc(-80%);
				}

			}

			h2 {
				margin: 0;

				@media screen and (max-width: 767px) {
					font-size: 1.25rem;
				}

				@media screen and (max-width: 576px) {
					font-size: 0.6667rem;
				}
			}
		}

		&--milestone {
			display: inline-block;

			&.goalone {
				margin-left: 0;
			}

			&.goaltwo {
				margin-left: calc(28% - 7.5rem);

				@media screen and (max-width: 767px) {
					margin-left: calc(28%  - 7rem);
				}

				@media screen and (max-width: 576px) {
					margin-left: calc(28% - 4.25rem);
				}

				@media only screen and (max-width : 320px) {
					margin-left: calc(25%  - 4rem);
				}
			}

			&.goalthree {
				margin-left: calc(29% - 7.5rem);

				@media screen and (max-width: 576px) {
					margin-left: calc(29% - 3.5rem);
				}
			}

			&.finalgoal {
				margin-left: auto;

				@media screen and (max-width: 767px) {
					margin-left: -2rem;
				}

				@media screen and (max-width: 576px) {
					margin-left: auto;
					margin-top: -5px;
				}

				@media only screen and (max-width: 320px) {
					margin-left: -15px;
				}

			}
		}

		&--image {
			@media screen and (max-width: 576px) {
				height: 85px;
				width: auto;
				position: static;
			}
		}
	}

	&__progress {
		&--container {
			margin-top: -6em;
			width: span(8 of 12);
			margin-left: span(2 of 12);
			z-index: 3;
			position: relative;
		}

		&--bar {
			display: inline-block;
			position: relative;
			height: 100px;

			&.visible {
				-webkit-animation: progress-bar 2s;
				-moz-animation: progress-bar 2s;
				animation: progress-bar 2s;
			}
		}

		&--imagecontainer {
			display: inline;
		}

		&--image {
			display: inline-block;
			position: absolute;
			width: auto;
			height: 6.5rem;
			margin-left: -100px;
			margin-top: -43px;

			@media screen and (max-width: 767px) {
				height: 4rem;
				margin-left: -50px;
				margin-top: -10px;
			}

			@media screen and (max-width: 576px) {
				height: 2.5rem;
				margin-left: -50px;
				margin-top: calc(2.5rem);
			}
		}
	}
}
