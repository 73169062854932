.admin-bar .banner {
	top: 46px;
}

@media screen and (min-width: 783px) {
	.admin-bar .banner {
		top: 32px;
	}
}

.admin-bar .banner .container {
	top: 46px;
}

@media screen and (min-width: 783px) {
	.admin-bar .banner .container {
		top: 32px;
	}
}

.admin-bar .social__banner {
	top: 126px;
}

@media screen and (min-width: 783px) {
	.admin-bar .social__banner {
		top: 112px;
	}
}

.alert {
	position: fixed;
	width: 100%;
	color: $white;
	background: $brand-secondary;
	z-index: 1001;
	height: 40px;
	line-height: 40px;
	text-align: center;

	a {
		color: $white;
		font-weight: 600;
		text-decoration: underline;
	}
}

.banner {
	@include container();
	height: 50px;
	position: relative;
	width: 100%;

	@include breakpoint($small) {
		height: 80px;
	}

	.container {
		@include container();
		position: fixed;
		z-index: 1000;
		top: 0;
		background-color: $white;
		width: 100%;
		border-bottom: 1px solid $gray-lighter;


		@include breakpoint($small) {
			border-bottom: 1px solid $gray-lighter;
		}
	}

	.menu-bar {
		@include container();
		position: fixed;
		top: 0;
		z-index: 10;
		width: 100%;
		background-color: $white;
		border-bottom: 1px solid $gray-lighter;

		@include breakpoint($small) {
			position: relative;
			background-color: transparent;
			width: auto;
			float: left;
			border-bottom: 0;
			margin: 0 gutter(6);
		}
	}

	.brand {
		@include hide-text();
		height: 50px;
		line-height: 50px;
		float: left;
		width: 150px;
		background-size: 150px auto;
		background-repeat: no-repeat;
		margin-left: 2%;

		@include breakpoint($small) {
			height: 80px;
			line-height: 80px;
			margin-left: 0;
		}
	}

	.menu-drawer {
		position: relative;
		display: block;
		float: right;
		top: 0;
		height: 50px;
		width: 50px;
		border-radius: 50%;

		/* image replacement */
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;

		transition: transform .3s;

		.nav-icon {
			/* icon created in CSS */
			@include center;
			position: absolute;
			width: 20px;
			height: 2px;
			border-radius: 1px;
			background-color: $brand-secondary;
			transition: background-color .3s;

			&:before,
			&:after {
				/* upper and lower lines of the menu icon */
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color: inherit;
				border-radius: 1px;


				/* Force Hardware Acceleration in WebKit */
				transform: translateZ(0);
				backface-visibility: hidden;

				transition: transform .3s, width .3s, top .3s;
			}

			&:before {
				transform: translateY(-5px);
			}

			&:after {
				transform: translateY(5px);
			}

			@include breakpoint($small) {
				visibility: hidden;
				display: none;
			}
		}

		.navigation-is-open & {
			/* rotate trigger when navigation becomes visible */
			transform: rotate(180deg);

			.nav-icon {
				background-color: $white;
				transition: background-color .3s;
			}

			.nav-icon::after,
			.nav-icon::before {
				/* animate arrow --> from hamburger to arrow */
				background-color: $brand-secondary;
				transition: transform .3s;
			}

			.nav-icon:before {
				transform: rotate(135deg);
			}

			.nav-icon:after {
				transform: rotate(225deg);
			}

			.no-touch &:hover .nav-icon:after,
			.no-touch &:hover .nav-icon:before {
				top: 0;
			}
		}
	}

	.nav-container {
		@include container();
		/* all navigation content */
		height: calc(100% - 50px);
		background-color: $white;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: $padding-base-vertical;

		/* Force Hardware Acceleration in WebKit */
		transform: translateZ(0);
		backface-visibility: hidden;

		transform: translateY(-100%);
		transition: transform .3s;
		transition-timing-function: cubic-bezier(.6,0,.45,.99);

		@include breakpoint($small) {
			height: 80px;
			transform: translateY(0);
			padding-bottom: 0;
			background-color: transparent;
			overflow: visible;
		}
	}

	.nav-wrapper {
		position: fixed;
		z-index: 2;
		top: 50px;
		left: 0;
		height: 100%;
		width: 100%;
		visibility: hidden;
		transition: visibility 0s .3s;

		.navigation-is-open & {
			visibility: visible;
			transition: visibility 0s 0s;

			.nav-container {
				transform: translateY(0);
				transition: transform .3s;
				transition-timing-function: cubic-bezier(.6,0,.45,.99);
			}
		}

		@include breakpoint($small) {
			position: relative;
			visibility: visible;
			top: 0;
			width: auto;
		}
	}

	.nav {
		list-style: none;
		margin: 0;
		padding: 0;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 2;
		text-align: center;

		& .sub-menu {
			list-style: none;
			margin: 0;
			padding: 0;
			font-weight: 500;
			font-size: ms(-1);

			.menu-item {
				margin-bottom: $padding-base-vertical / 2;
			}
		}

		.menu-item {
			margin-bottom: $padding-base-vertical;

			a {
				color: $text-color;
				text-decoration: underline;

				@include breakpoint($small) {
					text-decoration: none;
				}
			}

			.sub-menu a {
				text-decoration: none;
			}
		}
	}

	.cta-nav {
		margin-top: $padding-base-vertical;
		width: span(11 of 12);

		.nav {
			@include container();
			width: 100%;
		}

		.cta-call,
		.cta-item {
			@include span(6 of 12);
			font-size: ms(-1);

			a {
				@extend %btn--secondary-outline;
				color: $brand-secondary;
				line-height: 1;
				letter-spacing: 0;
				text-decoration: none;
			}
		}

		.cta-app {
			display: none;
		}
	}

	@include breakpoint($small) {
		.nav-wrapper {
			position: relative;
			width: calc(100% - 3.333% - 150px);
			float: left;
		}

		.menu-drawer {
			display: none;
		}

		.nav-container {
			width: 100%;
		}

		.nav {
			@include container();
			margin: 0;
			padding: 0;
			list-style: none;
			font-weight: 500;
			text-transform: none;

			@include breakpoint($small) {
				height: 80px;
				line-height: 80px;
			}

			li,
			a {
				display: inline-block;
			}

			li {
				text-align: center;
			}

			a {
				text-decoration: none;
				color: $text-color;
				transition: color .3s;

				&:hover {
					color: $brand-secondary;
				}
			}

			.active {
				> a {
					color: $brand-secondary;
				}
			}
		}

		.nav-primary {
			float: left;
			width: auto;

			.nav {
				> li {
					position: relative;
					padding: 0 $padding-base-vertical / 2;
					font-size: ms(-1);
					margin-bottom: 0;

					&:before {
						content: "";
						height: 5px;
						width: 100%;
						background-color: $brand-secondary;
						position: absolute;
						top: -6px;
						left: 0;
						transition: top .3s ease-in-out;
					}

					> a {
						transition: color .3s;

						&:hover {
							color: $brand-secondary;
						}
					}

					&:hover {
						&:before {
							top: -1px;
						}

						> ul {
							opacity: 1;
							visibility: visible;
							margin: 0;
							transition: margin .3s, opacity .3s;
						}
					}

					&.active {
						&:before {
							top: 0;
						}
					}
				}

				.sub-menu {
					min-width: 150px;
					width: 110%;
					position: absolute;
					top: 100%;
					left: 0;
					z-index: 1;
					opacity: 0;
					visibility: hidden;
					transition: margin .3s, opacity .3s;
					background-color: $white;
					margin: 0;
					padding: 0;
					border-top: 5px solid $brand-secondary;
					line-height: 3;
					box-shadow: 0 2px 4px rgba($gray-base, .25);
					font-size: ms(-1);

					> li {
						width: 100%;
						text-align: left;
						padding: 0 $padding-base-vertical;
						background-color: $white;
						transition: background-color .3s;
						margin-bottom: 0;

						&.active,
						&:hover {
							background-color: $gray-lighter;

							a {
								color: $text-color;
							}
						}
					}

					a {
						width: 100%;
					}
				}
			}
		}

		.cta-nav {
			float: right;
			width: auto;
			text-transform: uppercase;
			text-align: right;
			margin-top: 0;

			.nav {
				width: 100%;
				margin-left: 0;
				margin-right: gutter(6);

				> li {
					position: relative;
					font-size: ms(-1);
					padding: 0 $padding-base-vertical;

					&:before {
						content: "";
						height: 5px;
						width: 100%;
						background-color: $brand-secondary;
						position: absolute;
						top: -6px;
						left: 0;
						transition: top .3s ease-in-out;
					}

					&:hover {
						&:before {
							top: -1px;
						}
					}
				}
			}

			.cta-app,
			.cta-call {
				display: inline-block;
				width: auto;
				float: none;
			}

			.cta-item {
				width: auto;
				float: none;

				a {
					letter-spacing: 2px;
					font-size: ms(-2);
				}

				&:before {
					display: none;
				}
			}

			.cta-call {
				a {
					padding: 0;
					border: 0;
					color: $text-color;

					&:hover {
						background-color: transparent;
						color: $text-color;
					}
				}
			}
		}
	}

	@include breakpoint($medium) {
		.nav-primary {
			.nav {
				&>li {
					padding: 0 $padding-base-vertical * 2;
					font-size: ms(0);
				}
			}
		}

		.cta-nav {

			.nav {
				&>li {
					font-size: ms(0);
				}
			}

			.cta-item {
				a {
					font-size: ms(-2);
				}
			}
		}
	}
}

.social__banner {
	@include span(3 of 12);
	@include push(9 of 12);
	margin-left: 70%;
	position: fixed;
	top: 50px;
	background-color: transparent;
	z-index: 10;

	@include breakpoint($small) {
		top: 80px;
	}
}

.social__item {
	position: relative;
	width: 40px;
	height: 40px;
	float: right;
	background-color: $brand-secondary;

	&--facebook {
		background-color: #3b5998;
	}
}

.social__link {
	width: 100%;
	height: 100%;
	display: block;
	line-height: 38px;
}

.social__icon {
	width: 40px;
	height: 20px;
	vertical-align: middle;
}

.flexbox {

	.nav-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;

		@include breakpoint($small) {
			flex-direction: column;
			justify-content: flex-end;
		}
	}

	.nav-primary,
	.cta-nav {
		display: flex;
		flex-wrap: wrap;
	}

	.cta-nav {
		order: 1;
		align-items: center;
		justify-content: center;
	}

	.nav-primary {
		order: 2;
		justify-content: center;
	}

	@include breakpoint($small) {
		.cta-nav {
			order: 2;
			justify-content: flex-end;
			display: flex;

			.nav {
				width: auto;
			}
		}

		.nav-primary {
			order: 1;
			justify-content: flex-start;
		}
	}
}
