// -----------
// Icon Styles
// -----------

.icon {
  @include container();
  position: relative;
  // padding: $padding-base-vertical * 2 0;

  &__list {
    @include container();
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    list-style: none;

    @include breakpoint($medium) {
      max-width: 960px;
    }
  }

  &__item {
    @include span(6 of 12);
    position: relative;
    margin-bottom: $padding-base-vertical;

    @include breakpoint($small) {
      @include span(4 of 12);
    }

    @include breakpoint($medium) {
      @include span(2 of 12);
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    color: $text-color;
    text-align: center;

    &:hover {
      text-decoration: none;

      .icon__text {
        color: $brand-secondary;
      }
    }
  }

  &__icon {
    width: 100%;
    max-width: 100px;
    margin: 0 auto;
    text-align: center;
  }

  &__text {
    color: $text-color;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    transition: color .3s;
    margin-top: 0;

    @include breakpoint($small) {
      letter-spacing: 1px;
    }

    &:hover {
      text-decoration: none;
      color: $brand-secondary;
    }
  }
}
