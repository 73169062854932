h1 {
	@include typi($h1-map);
}

h2 {
	@include typi($h2-map);
}

h3 {
	@include typi($h3-map);
}

h4 {
	@include typi($h4-map);
}

/* lato-300 - latin */
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 300;
	src: url("../fonts/lato-v22-latin-300.eot"); /* IE9 Compat Modes */
	src: url("../fonts/lato-v22-latin-300.eot?#iefix") format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/lato-v22-latin-300.woff2") format("woff2"),
		/* Super Modern Browsers */ url("../fonts/lato-v22-latin-300.woff")
			format("woff"),
		/* Modern Browsers */ url("../fonts/lato-v22-latin-300.ttf")
			format("truetype"),
		/* Safari, Android, iOS */ url("../fonts/lato-v22-latin-300.svg#Lato")
			format("svg"); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/lato-v22-latin-regular.eot"); /* IE9 Compat Modes */
	src: url("../fonts/lato-v22-latin-regular.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/lato-v22-latin-regular.woff2") format("woff2"),
		/* Super Modern Browsers */ url("../fonts/lato-v22-latin-regular.woff")
			format("woff"),
		/* Modern Browsers */ url("../fonts/lato-v22-latin-regular.ttf")
			format("truetype"),
		/* Safari, Android, iOS */ url("../fonts/lato-v22-latin-regular.svg#Lato")
			format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/lato-v22-latin-700.eot"); /* IE9 Compat Modes */
	src: url("../fonts/lato-v22-latin-700.eot?#iefix") format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/lato-v22-latin-700.woff2") format("woff2"),
		/* Super Modern Browsers */ url("../fonts/lato-v22-latin-700.woff")
			format("woff"),
		/* Modern Browsers */ url("../fonts/lato-v22-latin-700.ttf")
			format("truetype"),
		/* Safari, Android, iOS */ url("../fonts/lato-v22-latin-700.svg#Lato")
			format("svg"); /* Legacy iOS */
}
