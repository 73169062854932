body#tinymce {
	margin: 12px !important;
}

.textblock__button {
	a {
		@extend %btn--secondary;
	}

	&--outline {
		a {
			@extend %btn--secondary-outline;
		}
	}
}
