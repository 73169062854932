// ---------------
// Feedback Styles
// ---------------

.feedbacklist {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;

	&__list {
		@include container();
		margin-left: auto;
		margin-right: auto;
		padding: 0;
		list-style: none;
	}

	.grid-sizer {
		@include span(6 of 12);

		@include breakpoint($small) {
			@include span(6 of 12);
		}
	}

	&__listitem {
		@include span(6 of 12);
		margin-bottom: $padding-base-vertical * 2;
		position: relative;

		@include breakpoint($small) {
			@include span(6 of 12);
		}
	}

	&__background {
		background-color: $off-white;
		padding: $padding-base-vertical;
	}

	&__title {
		display: block;
		color: $text-color;
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;
		margin-bottom: $padding-base-vertical;
		letter-spacing: 2px;
	}

	&__meta {
		text-align: center;
	}
}
