// --------------
// Gallery Styles
// --------------

.gallery {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;

	&--gray {
		background-color: $off-white;
	}

	&--white {
		background-color: $white;
	}

	&__list {
		@include container();
		margin-left: auto;
		margin-right: auto;
		list-style: none;
		max-width: 800px;
		padding: $padding-base-vertical * 2;
	}

	&__item {
		@include span(12 of 12);
		position: relative;
		margin-bottom: $padding-base-vertical / 2;

		@include breakpoint($small) {
			@include span(6 of 12);

			&--full {
				@include span(12 of 12);
			}

			&--regular + &--full {
				@include span(6 of 12);
			}
		}
	}

	&__title {
		display: block;
		color: $text-color;
		text-align: center;
		font-weight: 400;
	}
}
